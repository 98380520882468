import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { headers } from 'actions/defaults';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AddSponsoredMinutesDialog from './AddSponsoredMinutesDialog';
import { API_URL } from '../../../environment';
import { fetchClient } from '../../../actions/admin/user';

const isAdmin = window.location.pathname.split('/')[1] === 'admin';

const sectionStyle = {
  marginTop: '32px',
};

const titleStyle = {
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: '600',
  marginBottom: '8px',
  color: '#3c75c7',
  letterSpacing: '0.2px',
};

const descStyle = {
  wordWrap: 'break-word',
  fontSize: '16px',
  marginBottom: '16px',
};

class AvailableServicesBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      snackOpen: false,
      errorMessage: null,
      message: null,
    };
  }

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleSubmit = async data => {
    const { client } = this.props;
    try {
      const url = `${API_URL}/admin/additional_minutes/add`;

      const response = await axios.put(
        url,
        {
          client_id: client.id,
          counselling_type: data.counsellingType,
          hours: data.hours,
        },
        headers(),
      );
      this.setState({
        message: response.data.message,
      });
    } catch (error) {
      this.setState({
        errorMessage: error.response?.data?.error,
      });
    } finally {
      this.handleCloseDialog();
      this.setState({ snackOpen: true });
      this.props.fetchClient(client.id);
    }
  };

  renderIntro = () => {
    const { client } = this.props;
    const { available_services: services } = client;

    const hasAnyServices = Object.values(services).some(service => service);

    return (
      <div style={sectionStyle}>
        <p style={descStyle}>
          {hasAnyServices ? (
            <FormattedMessage defaultMessage="This client has access to the following services through GreenShield Health." />
          ) : (
            <FormattedMessage defaultMessage="This client does not have access to any additional services through GreenShield Health at this time." />
          )}
        </p>
      </div>
    );
  };

  renderPlanSponsor = () => {
    const { client } = this.props;

    return (
      <div style={sectionStyle}>
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Corporate Plan Sponsor Information" />
          </p>
          <div>
            <p style={descStyle}>
              {client.company?.name || (
                <FormattedMessage defaultMessage="N/A" />
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderReseller = () => {
    const { client } = this.props;
    return (
      <div style={sectionStyle}>
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Reseller Company" />
          </p>
          <div>{client.reseller && <p>{client.reseller.name}</p>}</div>
        </div>
      </div>
    );
  };

  renderEapProductBrandName = () => {
    const { client } = this.props;

    return (
      <div style={sectionStyle}>
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="EAP Product Brand Name" />
          </p>
          <div>
            <p>
              {client.eap_product_brand_name || (
                <FormattedMessage defaultMessage="N/A" />
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderRemainingSponsoredHours = () => {
    const { client } = this.props;
    const { sponsored_hours: sponsoredHours } = client;

    return (
      <div style={sectionStyle}>
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Remaining Sponsored Hours" />
          </p>
          <div>
            {'individual' in sponsoredHours && (
              <p style={descStyle}>
                <FormattedMessage
                  defaultMessage="<b>Individual</b>: {hours} hours"
                  values={{
                    hours: sponsoredHours.individual,
                    b: text => <b>{text}</b>,
                  }}
                />
              </p>
            )}
            {'couples' in sponsoredHours && (
              <p style={descStyle}>
                <FormattedMessage
                  defaultMessage="<b>Couples</b>: {hours} hours"
                  values={{
                    hours: sponsoredHours.couples,
                    b: text => <b>{text}</b>,
                  }}
                />
              </p>
            )}
            {'generic' in sponsoredHours &&
              sponsoredHours.generic.map(item => {
                const providerInfo =
                  item.provider_id &&
                  ` (${item.role_name}, ${item.provider_id})`;

                return (
                  <p style={descStyle}>
                    <FormattedMessage
                      defaultMessage="<b>{role}</b>: {hours} hours"
                      values={{
                        role: `${item.category_name}${providerInfo || ''}`,
                        hours: item.hours,
                        b: text => <b>{text}</b>,
                      }}
                    />
                  </p>
                );
              })}
          </div>
        </div>
        {isAdmin && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleOpenDialog}
            >
              <FormattedMessage defaultMessage="Add Additional Hours" />
            </Button>
            <AddSponsoredMinutesDialog
              open={this.state.isDialogOpen}
              onRequestClose={this.handleCloseDialog}
              onSubmit={this.handleSubmit}
            />
          </>
        )}
      </div>
    );
  };

  renderRemainingInsuranceSponsoredHours = () => {
    const { client } = this.props;
    const { insurance_coverage: coverage } = client;

    if (!coverage?.coverage_yn) {
      return <></>;
    }

    const individualHours = (
      coverage.available_sponsored_minutes_individual / 60
    ).toFixed(1);
    const couplesHours = (
      coverage.available_sponsored_minutes_couples / 60
    ).toFixed(1);

    const { sponsored_hours: sponsoredHours } = client;
    const key = Object.keys(sponsoredHours)[0];

    return (
      <div style={sectionStyle}>
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Insurance Sponsored Hours" />
          </p>
          <div>
            {(isAdmin || key === 'individual') && (
              <p style={descStyle}>
                <FormattedMessage
                  defaultMessage="<b>Individual</b>: {hours} hours"
                  values={{
                    hours: individualHours,
                    b: text => <b>{text}</b>,
                  }}
                />
              </p>
            )}
            {(isAdmin || key === 'couples') && (
              <p style={descStyle}>
                <FormattedMessage
                  defaultMessage="<b>Couples</b>: {hours} hours"
                  values={{
                    hours: couplesHours,
                    b: text => <b>{text}</b>,
                  }}
                />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderAdvisoryServices = () => {
    const { client } = this.props;
    const { available_services: services } = client;

    const hasFinancialAdvisory = services.financial_advisory;
    const hasHealthAdvisory = services.health_advisory;
    const hasLegalAdvisory = services.legal_advisory;
    const hasLifeTransitionsAdvisory = services.life_transitions_advisory;
    const hasCareerAdvisory = services.career_advisory;
    const hasAnyAdvisory =
      hasFinancialAdvisory ||
      hasHealthAdvisory ||
      hasLegalAdvisory ||
      hasLifeTransitionsAdvisory ||
      hasCareerAdvisory;

    if (!hasAnyAdvisory) return null;

    return (
      <div style={sectionStyle}>
        <p style={titleStyle}>
          <FormattedMessage defaultMessage="Advisory Services" />
        </p>
        {hasFinancialAdvisory && (
          <div>
            <p style={descStyle}>
              <b>
                <FormattedMessage tagName="label" defaultMessage="Financial" />
              </b>
              <br />
              <FormattedMessage defaultMessage="Access to a full-service financial hotline staffed by professional financial advisors." />
            </p>
          </div>
        )}
        {hasHealthAdvisory && (
          <div>
            <p style={descStyle}>
              <b>
                <FormattedMessage
                  tagName="label"
                  defaultMessage="Health Coaching"
                />
              </b>
              <br />
              <FormattedMessage defaultMessage="Personalized support from registered healthcare professionals. Includes support for smoking cessation, nutrition advice, navigating the healthcare system, and more." />
            </p>
          </div>
        )}
        {hasLegalAdvisory && (
          <div>
            <p style={descStyle}>
              <b>
                <FormattedMessage tagName="label" defaultMessage="Legal" />
              </b>
              <br />
              <FormattedMessage defaultMessage="A legal advice hotline with access to highly qualified attorneys for coverage of most types of legal concerns." />
            </p>
          </div>
        )}
        {hasLifeTransitionsAdvisory && (
          <div>
            <p style={descStyle}>
              <b>
                <FormattedMessage
                  tagName="label"
                  defaultMessage="Life Transitions"
                />
              </b>
              <br />
              <FormattedMessage defaultMessage="Resources to assist with childcare and eldercare choices as well as adapting to various life transitions such as new workplace adjustments, the arrival of a new baby, empty-nesting, and more." />
            </p>
          </div>
        )}
        {hasCareerAdvisory && (
          <div>
            <p style={descStyle}>
              <b>
                <FormattedMessage
                  tagName="label"
                  defaultMessage="Career Coaching"
                />
              </b>
              <br />
              <FormattedMessage defaultMessage="One-on-one coaching with a certified career coach, where clients can discuss career goals and pathways, as well as learn networking and practical skills." />
            </p>
          </div>
        )}
      </div>
    );
  };

  renderIcbtServices = () => {
    const { client } = this.props;
    const { available_services: services } = client;

    const hasIcbtSelfDirected = services.icbt_self_directed;
    const hasIcbtCoachAssisted = services.icbt_coach_assisted;
    const hasAnyIcbt = hasIcbtSelfDirected || hasIcbtCoachAssisted;

    if (!hasAnyIcbt) {
      return;
    }

    return (
      <div style={sectionStyle}>
        <p style={titleStyle}>
          <FormattedMessage defaultMessage="Internet-based Cognitive Behavioral Therapy (Tranquility iCBT)" />
        </p>
        {hasIcbtSelfDirected && (
          <div>
            <p style={descStyle}>
              <b>
                <FormattedMessage
                  tagName="label"
                  defaultMessage="Self-Directed"
                />
              </b>
              <br />
              <FormattedMessage defaultMessage="Access a program of self-directed psychoeducational modules,concepts and proprietary tools, aimed at helping clients learn how to manage their anxiety and/or depression." />
            </p>
          </div>
        )}
        {hasIcbtCoachAssisted && (
          <div>
            <p style={descStyle}>
              <b>
                <FormattedMessage
                  tagName="label"
                  defaultMessage="Coach-Assisted"
                />
              </b>
              <br />
              <FormattedMessage defaultMessage="Access a program of psychoeducational modules, concepts, coaching appointments, and proprietary tools aimed at helping clients learn how to manage their anxiety and/or depression." />
            </p>
          </div>
        )}
      </div>
    );
  };

  renderCouples = () => {
    const { client } = this.props;

    return (
      client.available_services?.couples_counselling && (
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Couples Counselling" />
          </p>
          <div>
            <p style={descStyle}>
              <FormattedMessage defaultMessage="Counselling focused on partner relationships." />
            </p>
          </div>
        </div>
      )
    );
  };

  renderCollaborativeCare = () => {
    const { client } = this.props;

    return (
      client.available_services?.collaborative_care && (
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Collaborative Care" />
          </p>
          <div>
            <p style={descStyle}>
              <FormattedMessage
                defaultMessage="You can refer this client for a collaborative medical
                or psychiatric assessment. If this is required, please email <a>Clinical Services Team</a>."
                values={{
                  a: chunks => {
                    const locale = localStorage.getItem('locale') || 'en';
                    const email =
                      locale === 'en'
                        ? 'workhealth@greenshield.ca'
                        : 'santeautravail@greenshield.ca';
                    return <a href={`mailto:${email}`}>{chunks}</a>;
                  },
                }}
              />
            </p>
          </div>
        </div>
      )
    );
  };

  renderPsychiatricAssessment = () => {
    const { client } = this.props;

    return (
      client.available_services?.psychiatric_assessment && (
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Psychiatric Assessment" />
          </p>
          <div>
            <p style={descStyle}>
              <FormattedMessage
                defaultMessage=" You can refer this client for a psychiatric assessment through Inkblot. If this is required, please email the workhealth@greenshield.ca"
                values={{
                  email: (
                    // eslint-disable-next-line react/jsx-no-literals
                    <a href="mailto:workhealth@inkblottherapy.com">
                      <FormattedMessage defaultMessage="Clinical Services Team" />
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      )
    );
  };

  renderCrisis = () => {
    const { client } = this.props;

    return (
      client.available_services?.crisis_support && (
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Crisis Hotline" />
          </p>
          <div>
            <p style={descStyle}>
              <FormattedMessage defaultMessage="24/7 crisis hotline (1-855-933-0103) is visible on the client's GreenShield Health Therapy dashboard." />
            </p>
          </div>
        </div>
      )
    );
  };

  renderClinicalNavigator = () => {
    const { client } = this.props;

    return (
      client.available_services?.clinical_navigator && (
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Clinical Navigator" />
          </p>
          <div>
            <p style={descStyle}>
              <FormattedMessage defaultMessage="Access to free consultations with a Clinical Navigator who can assist the client in determining suitable care pathways and resources both within and outside the GreenShield Health platform." />
            </p>
          </div>
        </div>
      )
    );
  };

  renderMaple = () => {
    const { client } = this.props;

    return (
      client.available_services?.telemedicine && (
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Maple" />
          </p>
          <div>
            <p style={descStyle}>
              <FormattedMessage defaultMessage="Maple enables the client to connect with a Canadian-licensed GP for medical care within minutes - from their phone, tablet, or computer, 7 days a week, 365 days a year." />
            </p>
          </div>
        </div>
      )
    );
  };

  renderEERF = () => {
    const { client } = this.props;

    return (
      client.available_services?.emergency_loan && (
        <div style={sectionStyle}>
          <p style={titleStyle}>
            <FormattedMessage defaultMessage="Employee Emergency Relief Fund" />
          </p>
          <div>
            <p style={descStyle}>
              <FormattedMessage defaultMessage="Access to Employee Relief Fund services which can assist the client in dealing with temporary or immediate financial challenges during times of transition or trauma, including funerary services, emergency house or car repairs, or temporary medical expenses not otherwise covered by benefits." />
            </p>
          </div>
        </div>
      )
    );
  };

  renderOutro = () => {
    const { client } = this.props;
    const { available_services: services } = client;

    const hasAnyServices = Object.values(services).some(service => service);
    if (!hasAnyServices) return null;

    return (
      <div>
        <div style={{ ...sectionStyle, borderTop: '3px solid #eeeeee' }} />
        <div style={sectionStyle}>
          <p style={descStyle}>
            <FormattedMessage
              defaultMessage="If your client is having difficulty accessing these services, please ask them to contact our <a>support@greenshield.ca</a> or use the support chat."
              values={{
                a: address => <a href={`mailto:${address}`}>{address}</a>,
              }}
            />
          </p>
        </div>
      </div>
    );
  };

  render() {
    const { client, flags } = this.props;
    const { available_services: services } = client;

    if (!services) return <div />;

    return (
      <div className="available-services">
        {this.renderIntro()}
        {this.renderPlanSponsor()}
        {isAdmin && this.renderReseller()}
        {flags.pod2WhiteLabel && this.renderEapProductBrandName()}
        {this.renderRemainingSponsoredHours()}
        {this.renderRemainingInsuranceSponsoredHours()}
        {this.renderClinicalNavigator()}
        {this.renderAdvisoryServices()}
        {this.renderIcbtServices()}
        {this.renderMaple()}
        {this.renderEERF()}
        {this.renderCouples()}
        {this.renderCollaborativeCare()}
        {this.renderPsychiatricAssessment()}
        {this.renderCrisis()}
        {this.renderOutro()}
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackOpen: false })}
        >
          {this.state.errorMessage ? (
            <Alert severity="error">{this.state.errorMessage}</Alert>
          ) : (
            <Alert severity="success">{this.state.message}</Alert>
          )}
        </Snackbar>
      </div>
    );
  }
}

AvailableServicesBody.propTypes = {
  client: PropTypes.object.isRequired,
  fetchClient: PropTypes.func.isRequired,
  flags: PropTypes.object,
};

function mapStateToProps(state) {
  const { client } = state;

  return {
    client,
  };
}
export default withLDConsumer()(
  connect(mapStateToProps, { fetchClient })(AvailableServicesBody),
);
